import React from 'react';

import { Image, Text, Button, Layout } from '@/components';
import themes from '@/themes';
import { useChangePageState } from '@/state';

import welcomeLogo from '@/assets/images/aipia-logo.png';
import bgTop from '@/assets/images/bg-top-green.png';
import bgBottom from '@/assets/images/bg-bottom-green.png';
import arrows from '@/assets/images/arrows.png';

const Home = () => {
  const changePage = useChangePageState('registration');

  return (
    <Layout.PageWrapper
      styles={{
        background: `url("${bgTop}") left top no-repeat, url("${bgBottom}") right bottom no-repeat`,
        backgroundColor: '#6eb42e',
        backgroundSize: '80%, 80%',
      }}
    >
      <div
        style={{
          marginTop: '6rem',
        }}
      >
        <Image src={welcomeLogo} width="70%" />
      </div>
      <div
        style={{
          marginTop: '1.5rem',
        }}
      >
        <Text
          fontFamily="Raleway"
          size="2rem"
          weight="600"
          styles={{ lineHeight: '4rem' }}
        >
          Smart Packaging
        </Text>
        <Text fontFamily="Raleway" size="1.5rem">
          VIRTUAL CONGRESS
        </Text>
      </div>
      <div style={{ margin: '2rem 0' }}>
        <Button
          theme={themes.spinTheWheelAIPIA.whiteButton}
          onClick={changePage}
        >
          Start
        </Button>
      </div>
      <Image src={arrows} width="15%" />
    </Layout.PageWrapper>
  );
};

export default Home;
