declare var Winwheel: any;

type SpinWheelGameArgs = {
  canvasId: string;
  numberOfSegments: number;
  segments: { text: string }[];
  pointerAngle?: number;
  onFinish: (segment: { [prop: string]: any }) => void;
};

export default class SpinWheelGame {
  private game = null;

  constructor({
    canvasId,
    numberOfSegments,
    segments,
    pointerAngle = 0,
    onFinish,
  }: SpinWheelGameArgs) {
    this.game = new Winwheel({
      canvasId,
      drawMode: 'image',
      pointerAngle,
      numSegments: numberOfSegments,
      segments,
      responsive: true,
      animation: {
        type: 'spinToStop',
        duration: 5,
        spins: 8,
        callbackFinished: onFinish,
      },
    });
  }

  initBackgroundImage(src: any, width: number, height?: number) {
    const img = new Image(width, height || width);
    img.src = src;

    img.onload = () => {
      (this.game as any).wheelImage = img;
      (this.game as any).draw();
    };
  }

  run() {
    (this.game as any).startAnimation();
  }

  getWinSegment() {
    return (this.game as any).getIndicatedSegment();
  }
}
