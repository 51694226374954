import React from 'react';

import { TileWrapper, TileImage } from '@/components/games/scratch-off/styled';

type TileProps = {
  coverImg: string;
  iconImg: string;
  winIconImg: string;
  isOpened: boolean;
  isWin: boolean;
  hasWinCombination: boolean;
  onClick: () => void;
};

const Tile = ({
  coverImg,
  iconImg,
  winIconImg,
  isOpened,
  isWin,
  hasWinCombination,
  onClick,
}: TileProps) => (
  <TileWrapper onClick={onClick}>
    <TileImage src={coverImg} opacity={isOpened ? 0 : 1} />
    <TileImage src={iconImg} opacity={isOpened || hasWinCombination ? 1 : 0} />
    <TileImage src={winIconImg} opacity={isWin ? 1 : 0} />
  </TileWrapper>
);

export default Tile;
