import React from 'react';

type FormProps = {
  styles?: { [prop: string]: any };
  onSubmit: (data: any) => void;
  children: React.ReactNode;
};

const Form = ({ styles, children, onSubmit }: FormProps) => (
  <form style={styles} onSubmit={onSubmit}>
    {children}
  </form>
);

export default Form;
