import React from 'react';
import { useRecoilValue } from 'recoil';

import { Button, Image, Text, Layout } from '@/components';
import themes from '@/themes';
import { factState, useChangePageState } from '@/state';

import logo from '@/assets/images/aipia-logo.png';
import nfcIcon from '@/assets/images/spin-win-nfc.png';
import qrcIcon from '@/assets/images/spin-win-qr-code.png';
import tntIcon from '@/assets/images/spin-win-track-and-trace.png';
import marketIcon from '@/assets/images/spin-win-market.png';
import bgSpin from '@/assets/images/bg-spin.png';

const iconsMap: { [prop: string]: any } = {
  nfcIcon,
  qrcIcon,
  tntIcon,
  marketIcon,
};

const Win = () => {
  const fact = useRecoilValue(factState);
  const changePage = useChangePageState('fact');

  return (
    <Layout.PageWrapper
      styles={{
        background: `transparent url("${bgSpin}") center no-repeat`,
        backgroundSize: '100%',
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <Layout.ContentWrapper>
        <div style={{ marginTop: '0.5rem' }}>
          <Image src={logo} width="50%" />
        </div>
        <div style={{ margin: '1rem 0 0' }}>
          <Text weight="600" size="1.2rem" styles={{ lineHeight: '2rem' }}>
            You won!
          </Text>
        </div>
        <Image src={iconsMap[`${fact}Icon`]} width="70%" />
        <Button
          theme={themes.spinTheWheelAIPIA.factButton}
          onClick={changePage}
        >
          Next
        </Button>
      </Layout.ContentWrapper>
    </Layout.PageWrapper>
  );
};

export default Win;
