import { AxiosError } from 'axios';

import { Segment } from '../types';

export const processAxiosError = (e: AxiosError | undefined) => {
  return e && e.response ? e && e.response.data : null;
};

const removeFirstStringChar = (s: string): string => s.substr(1);

const decodeURISegments = (a: string[]): string[] =>
  a.map((s) => window.decodeURI(s)).filter((s) => s);

const createMapFromURISegments = (
  segments: string[]
): { [prop: string]: any } =>
  segments.reduce((acc, segment) => {
    const segmentParts = segment.split('=');
    return { ...acc, [segmentParts[0]]: segmentParts[1] };
  }, {});

const normalizeUUID = (uuid: string): string => {
  if (uuid.includes('-')) {
    return uuid;
  }

  return `${uuid.substr(0, 8)}-${uuid.substr(8, 4)}-${uuid.substr(
    12,
    4
  )}-${uuid.substr(16, 4)}-${uuid.substr(20)}`;
};

const normalizeQueryParameter = (
  queryMap: {
    [prop: string]: any;
  },
  paramName: string,
  normalizer: (p: any) => any
): { [prop: string]: any } => {
  if (queryMap.hasOwnProperty(paramName)) {
    return {
      ...queryMap,
      [paramName]: normalizer(queryMap[paramName]),
    };
  }

  return queryMap;
};

export const getUrlParams = (): {
  params: string[];
  queryMap: { [prop: string]: any };
} => {
  const { pathname, search } = window.location;
  const params = pathname.split('/').filter((v) => v);
  const searchWithoutQuestionMark = removeFirstStringChar(search);
  const searchSegments = searchWithoutQuestionMark.split('&');
  const decodedSearchSegments = decodeURISegments(searchSegments);
  const queryMap = createMapFromURISegments(decodedSearchSegments);

  return {
    params,
    queryMap: normalizeQueryParameter(queryMap, 'tapWowTagId', normalizeUUID),
  };
};

export const mapSegmentToCategory = (segment: Segment) => {
  const categoryMap = {
    nfc: 'tech',
    qrc: 'tech',
    market: 'brand',
    tnt: 'sp',
  };

  return categoryMap[segment];
};
