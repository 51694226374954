import React from 'react';

import { Image, Text, SpinWheel, Layout } from '@/components';

import logo from '@/assets/images/aipia-logo.png';
import bgBottom from '@/assets/images/bg-bottom-green.png';
import bgSpin from '@/assets/images/bg-spin.png';

const SpinTheWheel = () => (
  <Layout.PageWrapper
    styles={{
      background: `url("${bgBottom}") right bottom no-repeat, url("${bgSpin}") center no-repeat`,
      backgroundSize: '60%, 100%',
      paddingLeft: 0,
      paddingRight: 0,
    }}
  >
    <Layout.ContentWrapper>
      <div style={{ marginTop: '0.5rem' }}>
        <Image src={logo} width="50%" />
      </div>
      <div style={{ margin: '0.5rem 0' }}>
        <Text weight="600" size="1.2rem" styles={{ lineHeight: '2rem' }}>
          Spin the wheel!
        </Text>
      </div>
      <SpinWheel
        numberOfSegments={8}
        pointerAngle={23}
        segments={[
          { text: 'nfc' },
          { text: 'qrc' },
          { text: 'market' },
          { text: 'tnt' },
          { text: 'nfc' },
          { text: 'qrc' },
          { text: 'market' },
          { text: 'tnt' },
        ]}
      />
    </Layout.ContentWrapper>
  </Layout.PageWrapper>
);

export default SpinTheWheel;
