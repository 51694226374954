import React from 'react';

import { StyledPageWrapper, StyledContentWrapper } from './styled';
import { ICommonPageWrapper, ICommonContentWrapper } from './type';

interface IPageWrapperProps extends ICommonPageWrapper {
  children: React.ReactNode;
}

interface IContentWrapperProps extends ICommonContentWrapper {
  children: React.ReactNode;
}

const PageWrapper = ({ children, styles }: IPageWrapperProps) => (
  <StyledPageWrapper styles={styles}>{children}</StyledPageWrapper>
);

const ContentWrapper = ({ children, styles }: IContentWrapperProps) => (
  <StyledContentWrapper styles={styles}>{children}</StyledContentWrapper>
);

export default {
  PageWrapper,
  ContentWrapper,
};
