import React from 'react';

import { Image, Text, Layout, RegistrationForm } from '@/components';

import logo from '@/assets/images/aipia-logo.png';
import bgBottom from '@/assets/images/bg-bottom-light-green.png';

const Registration = () => (
  <Layout.PageWrapper
    styles={{
      background: `#ffffff url("${bgBottom}") right bottom no-repeat`,
      backgroundSize: '60%',
    }}
  >
    <Layout.ContentWrapper styles={{ width: '90%' }}>
      <div style={{ marginTop: '0.5rem' }}>
        <Image src={logo} width="40%" />
      </div>
      <div style={{ margin: '0.5rem 0' }}>
        <Text
          color="#0D8F35"
          weight="600"
          size="1rem"
          styles={{ lineHeight: '1.2rem' }}
        >
          Welcome to
        </Text>
        <Text
          color="#0D8F35"
          weight="600"
          size="1rem"
          styles={{ lineHeight: '1.2rem' }}
        >
          AIPIA Virtual Congress 2020!
        </Text>
      </div>
      <div style={{ marginBottom: '1rem' }}>
        <Text color="#6E6E6E" size="0.7rem" styles={{ lineHeight: '1.2rem' }}>
          We have created an interactive game that will share some interesting
          facts about smart packaging. You can play a game or two and maybe
          learn something new. Everyone that registers below has a chance to win
          a free full conference pass to our congress next year in the
          Netherlands.
        </Text>
      </div>
      <RegistrationForm />
    </Layout.ContentWrapper>
  </Layout.PageWrapper>
);

export default Registration;
