import styled, { css } from 'styled-components';

import { IButtonContainerProps } from './types';

const resetCss = css`
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: 0;
  opacity: 1;
  transition: opacity 0.2s;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const ButtonContainer = styled.button<IButtonContainerProps>`
  ${resetCss}
  ${({ styles }) => styles}
  ${({ theme }) => theme}
`;
