import React from 'react';
import { useRecoilValue } from 'recoil';
import { shuffle } from 'lodash';

import { Image, Text, Layout } from '@/components';
import { factState, factsState, categoryState } from '@/state';

import logo from '@/assets/images/aipia-logo.png';
import bgBottom from '@/assets/images/bg-bottom-fact.png';
import nfcIcon from '@/assets/images/nfc-icon.png';
import qrcIcon from '@/assets/images/qr-code-icon.png';
import tntIcon from '@/assets/images/track-and-trace-icon.png';
import marketIcon from '@/assets/images/market-icon.png';

const iconsMap: { [prop: string]: any } = {
  nfcIcon,
  qrcIcon,
  tntIcon,
  marketIcon,
};

const Fact = () => {
  const fact = useRecoilValue(factState);
  const category = useRecoilValue(categoryState);
  const categories = useRecoilValue(factsState);

  const getCategoryText = (): string => {
    const categoriesList = categories.filter(
      ({ category: c }) => c === category
    );

    if (categoriesList.length) {
      return shuffle(categoriesList)[0].text;
    }

    return '';
  };

  return (
    <Layout.PageWrapper
      styles={{
        background: `transparent url("${bgBottom}") right bottom no-repeat`,
        backgroundSize: '60%',
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <Layout.ContentWrapper styles={{ width: '90%' }}>
        <div style={{ marginTop: '0.5rem' }}>
          <Image src={logo} width="50%" />
        </div>
        <div style={{ margin: '1rem 0 0' }}>
          <Text
            weight="600"
            size="1.1rem"
            color="#0D8F35"
            styles={{ lineHeight: '1rem' }}
          >
            Share the knowledge
          </Text>
        </div>
        <div style={{ marginTop: '1rem' }}>
          <Image src={iconsMap[`${fact}Icon`]} width="100%" />
        </div>
        <div style={{ marginTop: '1rem' }}>
          <Text color="#6E6E6E" size="0.85rem">
            {getCategoryText()}
          </Text>
        </div>
      </Layout.ContentWrapper>
    </Layout.PageWrapper>
  );
};

export default Fact;
