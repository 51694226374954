import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import {
  Fact,
  Home,
  ScratchOff,
  Registration,
  SpinTheWheel,
  Win,
} from '@/pages';
import { pageState, useChangeFactsState } from '@/state';
import { Fact as FactType } from './types';
import { getUrlParams } from '@/helpers';

type AppProps = {
  facts: FactType[];
  isAlreadyTapped: boolean;
};

const pagesMap: { [prop: string]: any } = {
  fact: Fact,
  home: Home,
  'scratch-off': ScratchOff,
  registration: Registration,
  'spin-the-wheel': SpinTheWheel,
  win: Win,
};

const App = ({ facts, isAlreadyTapped }: AppProps) => {
  const { queryMap } = getUrlParams();
  const page = useRecoilValue(pageState);
  const updateFacts = useChangeFactsState();

  useEffect(() => {
    const preloader = document.getElementById('preloader');
    preloader?.remove();
    updateFacts(facts);
  }, [updateFacts, facts]);

  const Page =
    page === 'registration' && isAlreadyTapped
      ? pagesMap[queryMap.gameType]
      : pagesMap[page];

  return <Page />;
};

export default App;
