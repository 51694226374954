import useAxios from 'axios-hooks';
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';

import { processAxiosError } from '@/helpers';

type UseApiReturnType = {
  response: AxiosResponse | undefined;
  loading: boolean;
  error: AxiosError | undefined;
  makeRequest: (data: any) => void;
};

const useApi = (config: AxiosRequestConfig): UseApiReturnType => {
  const [{ data: response, loading, error }, sendRequest] = useAxios(config, {
    manual: true,
  });

  const makeRequest = async (data: any) => {
    try {
      await sendRequest({ data });
    } catch (e) {
      toast.error('Request error. Please, try again.', {
        position: 'top-center',
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
  };

  return { response, loading, error: processAxiosError(error), makeRequest };
};

export default useApi;
