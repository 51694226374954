import React, { useState, useEffect } from 'react';
import { shuffle, intersection } from 'lodash';

import { Button } from '@/components';
import themes from '@/themes';
import {
  useChangeFactState,
  useChangePageState,
  useChangeCategoryState,
} from '@/state';
import Tile from './tile';
import { ScratchOffWrapper } from './styled';
import { mapSegmentToCategory } from '@/helpers';
import { Segment } from '../../../types';

import cover from '@/assets/images/scratch-off-cover.png';
import nfc from '@/assets/images/scratch-off-nfc-one.svg';
import nfcCombo from '@/assets/images/scratch-off-nfc-combo.svg';
import qrc from '@/assets/images/scratch-off-qrc-one.svg';
import qrcCombo from '@/assets/images/scratch-off-qrc-combo.svg';
import market from '@/assets/images/scratch-off-market-one.svg';
import marketCombo from '@/assets/images/scratch-off-market-combo.svg';
import tnt from '@/assets/images/scratch-off-track-and-trace-one.svg';
import tntCombo from '@/assets/images/scratch-off-track-and-trace-combo.svg';
import empty from '@/assets/images/scratch-off-empty.svg';

const iconsMap: { [prop: string]: any } = {
  nfc,
  nfcCombo,
  qrc,
  qrcCombo,
  market,
  marketCombo,
  tnt,
  tntCombo,
  empty,
};

const rawTiles = [
  ...Array(2).fill('qrc'),
  ...Array(2).fill('market'),
  ...Array(2).fill('tnt'),
  ...Array(2).fill('nfc'),
  ...Array(7).fill('empty'),
];
const winCard = shuffle(['qrc', 'nfc', 'market', 'tnt'])[0] as Segment;
const shuffledTiles = shuffle([...rawTiles, winCard]);
const winSequence = shuffledTiles.reduce(
  (acc: number[], tile: string, index: number) => {
    if (tile === winCard) {
      return [...acc, index];
    }

    return acc;
  },
  []
);

const ScratchOff = () => {
  const [opened, setOpened] = useState<number[]>([]);
  const changePage = useChangePageState('fact');
  const setWinFactType = useChangeFactState();
  const setCategoryType = useChangeCategoryState();

  useEffect(() => {
    const category = mapSegmentToCategory(winCard);
    setWinFactType(winCard);
    setCategoryType(category);
  }, []);

  const isWin = intersection(opened, winSequence).length === 3;

  const onTileClick = (id: number) => () => {
    if (opened.includes(id)) {
      return;
    }

    setOpened((prev) => [...prev, id]);
  };

  return (
    <>
      <ScratchOffWrapper>
        {shuffledTiles.map((tileName, i) => (
          <Tile
            key={i}
            coverImg={cover}
            iconImg={iconsMap[tileName]}
            winIconImg={iconsMap[`${tileName}Combo`]}
            isOpened={opened.includes(i)}
            isWin={isWin && winSequence.includes(i)}
            hasWinCombination={isWin}
            onClick={onTileClick(i)}
          />
        ))}
      </ScratchOffWrapper>
      <Button
        disabled={!isWin}
        theme={themes.spinTheWheelAIPIA.whiteButton}
        onClick={changePage}
      >
        Next
      </Button>
    </>
  );
};

export default ScratchOff;
