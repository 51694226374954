import React from 'react';

type ImageProps = {
  src: string;
  width: string;
  height?: string;
  styles?: {
    [props: string]: any;
  };
};

const Image = ({ src, width, height, styles }: ImageProps) => (
  <img src={src} width={width} height={height} alt="#" style={styles} />
);

export default Image;
