import styled, { css } from 'styled-components';

import { IInputWrapperProps } from './types';

type IInputElementProps = {
  hasError: boolean;
};

const resetCss = css`
  display: inline-block;
  width: 100%;
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: 0;
  -webkit-appearance: none;
  appearance: none;
  transition: border-bottom-color 0.2s ease-out;
`;

export const InputWrapper = styled.div<IInputWrapperProps>`
  position: relative;
  display: flex;
  width: 100%;

  &:before {
    position: absolute;
    display: ${({ required }) => (required ? 'block' : 'none')};
    top: 10px;
    font-size: 1rem;
    content: '*';
  }

  ${({ theme }) => theme}
`;

export const InputElement = styled.input<IInputElementProps>`
  ${resetCss}
  ${({ hasError }) =>
    hasError
      ? css`
          border-bottom: 2px solid #dd4444 !important;
        `
      : ''}
`;

export const InputError = styled.span`
  position: absolute;
  bottom: -16px;
  font-size: 3vw;
  color: #dd4444;
  font-family: 'Open Sans', monospace;
`;
