import React, { useEffect, useState } from 'react';

import { Image as AppImage, Button } from '@/components';
import { SpinWheelWrapper } from './styled';

import pointer from '@/assets/images/spin-wheel-pointer.png';
import spinWheelImg from '@/assets/images/spin-wheel.png';
import themes from '@/themes';
import {
  useChangePageState,
  useChangeFactState,
  useChangeCategoryState,
} from '@/state';
import SpinWheelGame from './lib';
import { Segment } from '../../../types';
import { mapSegmentToCategory } from '@/helpers';

type SpinWheelProps = {
  numberOfSegments: number;
  pointerAngle?: number;
  segments: { text: string }[];
};

const SpinWheel = ({
  numberOfSegments,
  pointerAngle,
  segments,
}: SpinWheelProps) => {
  const [width] = useState(window.innerHeight / 1.8);
  const [game, setGame] = useState<null | SpinWheelGame>(null);
  const [winSegment, setWinSegment] = useState<Segment | ''>('');
  const [gameStarted, setGameStarted] = useState(false);
  const changePage = useChangePageState('win');
  const setWinFactType = useChangeFactState();
  const setCategoryType = useChangeCategoryState();

  useEffect(() => {
    const g = new SpinWheelGame({
      canvasId: 'spin-wheel-canvas',
      numberOfSegments,
      pointerAngle,
      segments,
      onFinish: (segment) => setWinSegment(segment.text),
    });

    g.initBackgroundImage(spinWheelImg, width);

    setGame(g);
  }, [width]);

  useEffect(() => {
    if (winSegment) {
      const category = mapSegmentToCategory(winSegment);
      setWinFactType(winSegment);
      setCategoryType(category);
      setTimeout(() => changePage(), 500);
    }
  }, [winSegment, changePage, setWinFactType]);

  const spin = () => {
    if (game) {
      setGameStarted(true);
      game.run();
    }
  };

  return (
    <SpinWheelWrapper>
      <AppImage
        src={pointer}
        width="auto"
        height="auto"
        styles={{
          position: 'absolute',
          top: '-10px',
          left: 0,
          right: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
      <canvas id="spin-wheel-canvas" width={width} height={width}>
        Canvas not supported, please use another browser.
      </canvas>
      <Button
        theme={themes.spinTheWheelAIPIA.whiteButton}
        disabled={gameStarted}
        onClick={spin}
      >
        SPIN!
      </Button>
    </SpinWheelWrapper>
  );
};

export default SpinWheel;
