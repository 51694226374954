import styled from 'styled-components';

import { ITextContainerProps } from './types';

export const TextContainer = styled.p<ITextContainerProps>`
  margin: 0;
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ size }) => size};
  font-weight: ${({ weight }) => weight};
  color: ${({ color }) => color};
  ${({ styles }) => styles}
  ${({ theme }) => theme}
`;
