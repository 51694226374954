import { css } from 'styled-components';

const commonSpinTheWheelAIPIAButtonCss = css`
  padding: 2vh 18vw;
  color: #ffffff;
  font-family: 'Raleway', monospace;
  font-size: 1rem;
  border: 2px solid #ffffff;

  & span {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export default {
  spinTheWheelAIPIA: {
    whiteButton: css`
      ${commonSpinTheWheelAIPIAButtonCss}
    `,
    greenButton: css`
      ${commonSpinTheWheelAIPIAButtonCss}
      border-color: #6eb42e;

      & span {
        color: #6eb42e;
      }
    `,
    factButton: css`
      ${commonSpinTheWheelAIPIAButtonCss}
      padding-left: 10vw;
      padding-right: 10vw;
    `,
    textInput: css`
      &:before {
        color: #6e6e6e;
      }

      & input {
        padding: 2vw 4vw;
        font-size: 4.3vw;
        font-family: 'Raleway', monospace;
        color: #6e6e6e;
        border-bottom: 2px solid rgba(110, 110, 110, 0.5);

        &:focus {
          border-bottom: 2px solid #6eb42e;
        }

        &::placeholder {
          color: rgba(110, 110, 110, 0.5);
        }
      }
    `,
  },
};
