import { atom, useSetRecoilState } from 'recoil';

export const pageState = atom({
  key: 'pageState',
  default: 'home',
});

export const useChangePageState = (newState: string) => {
  const updateState = useSetRecoilState(pageState);
  return () => updateState(newState);
};
