type UrlsType = {
  GET_FACTS: string;
  GET_PARTICIPANT: (deviceId: string) => string;
  POST_PARTICIPANT: string;
};

export const urls: UrlsType = {
  GET_FACTS: `${process.env.REACT_APP_SERVER_HOST}/facts`,
  GET_PARTICIPANT: (deviceId: string) =>
    `${process.env.REACT_APP_SERVER_HOST}/participant/${deviceId}`,
  POST_PARTICIPANT: `${process.env.REACT_APP_SERVER_HOST}/participant`,
};
