import styled from 'styled-components';

type TileImage = {
  opacity: number;
};

export const ScratchOffWrapper = styled.div`
  display: grid;
  margin-bottom: 1rem;
  grid-template-columns: repeat(4, auto);
  column-gap: 10px;
  row-gap: 10px;
`;

export const TileWrapper = styled.div`
  position: relative;
  width: 73px;
  height: 73px;
  display: grid;
  grid-template-columns: repeat(4, auto);
  column-gap: 10px;
  row-gap: 10px;
`;

export const TileImage = styled.img<TileImage>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${({ opacity }) => opacity};
`;
