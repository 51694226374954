import React from 'react';

import { Image, Text, ScratchOff, Layout } from '@/components';

import logo from '@/assets/images/aipia-logo.png';
import bgBottom from '@/assets/images/bg-bottom-green.png';

const Wheel = () => (
  <Layout.PageWrapper
    styles={{
      background: `#6eb42e url("${bgBottom}") right bottom no-repeat`,
      backgroundSize: '60%',
    }}
  >
    <Layout.ContentWrapper styles={{ width: '90%' }}>
      <div style={{ marginTop: '0.5rem' }}>
        <Image src={logo} width="50%" />
      </div>
      <div style={{ margin: '0.5rem 0' }}>
        <Text weight="600" size="1.2rem" styles={{ lineHeight: '2rem' }}>
          Play game!
        </Text>
      </div>
      <ScratchOff />
    </Layout.ContentWrapper>
  </Layout.PageWrapper>
);

export default Wheel;
