import React from 'react';

import { TextContainer } from './styled';
import { ITextContainerProps } from './types';

interface ITextProps extends ITextContainerProps {
  children: React.ReactNode;
}

const Text = ({
  fontFamily = 'Open Sans',
  weight = '400',
  size = '1rem',
  color = '#ffffff',
  styles = {},
  theme = [],
  children,
}: ITextProps) => (
  <TextContainer
    fontFamily={fontFamily}
    size={size}
    color={color}
    weight={weight}
    styles={styles}
    theme={theme}
  >
    {children}
  </TextContainer>
);

export default Text;
