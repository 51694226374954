import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';

import { ICommonPageWrapper, ICommonContentWrapper } from './type';

const fadeInAnimation = keyframes`${fadeIn}`;

export const StyledPageWrapper = styled.div<ICommonPageWrapper>`
  display: flex;
  width: 100%;
  padding: 0.5rem 0.5rem 1rem;
  flex-direction: column;
  align-items: center;
  animation: 500ms ${fadeInAnimation};
  ${({ styles }) => styles}
`;

export const StyledContentWrapper = styled.div<ICommonContentWrapper>`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  ${({ styles }) => styles}
`;
