import React from 'react';

import { InputElement, InputWrapper, InputError } from './styled';
import { IInputWrapperProps } from './types';

interface IInputProps extends IInputWrapperProps {
  name: string;
  hasError?: boolean;
  errorMessage?: string;
  placeholder?: string;
  register: () => void;
}

const Input = ({
  name,
  placeholder = '',
  theme = [],
  required = false,
  hasError = false,
  errorMessage = '',
  register,
}: IInputProps) => (
  <InputWrapper theme={theme} required={required}>
    <InputElement
      name={name}
      placeholder={placeholder}
      hasError={hasError}
      ref={register}
    />
    {hasError ? <InputError>{errorMessage}</InputError> : null}
  </InputWrapper>
);

export default Input;
