import { atom, useSetRecoilState } from 'recoil';

import { Fact } from '../types';

export const factsState = atom({
  key: 'factsState',
  default: [] as Fact[] | [],
});

export const factState = atom({
  key: 'factState',
  default: '',
});

export const categoryState = atom({
  key: 'categoryState',
  default: '',
});

export const useChangeFactsState = () => {
  const updateState = useSetRecoilState(factsState);
  return (newState: Fact[] | []) => updateState(newState);
};

export const useChangeFactState = () => {
  const updateState = useSetRecoilState(factState);
  return (newState: string) => updateState(newState);
};

export const useChangeCategoryState = () => {
  const updateState = useSetRecoilState(categoryState);
  return (newState: string) => updateState(newState);
};
