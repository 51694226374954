import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { ToastContainer } from 'react-toastify';

import { Button, Form, Input, Spinner } from '@/components';
import themes from '@/themes';
import { getUrlParams } from '@/helpers';
import { useChangePageState } from '@/state';
import { useApi } from '@/hooks';
import { urls } from '@/urls';

type FormData = {
  username: string;
  email: string;
  phone: string;
};

const schema = yup.object().shape({
  username: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().notRequired(),
});

const RegistrationForm = () => {
  const { queryMap } = getUrlParams();
  const { response, loading, makeRequest } = useApi({
    url: urls.POST_PARTICIPANT,
    method: 'POST',
  });
  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const changePage = useChangePageState(queryMap.gameType || '');

  useEffect(() => {
    if (response) {
      changePage();
    }
  }, [response, changePage]);

  const onSubmit = async (data: FormData) => {
    await makeRequest({
      ...data,
      tagId: queryMap.tapWowTagId,
      deviceId: queryMap.deviceId,
      gameType: queryMap.gameType,
    });
  };

  return (
    <>
      <ToastContainer />
      <Spinner isShown={loading} />
      <Form
        styles={{
          display: 'flex',
          width: '90%',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          name="username"
          theme={themes.spinTheWheelAIPIA.textInput}
          placeholder="Name"
          required={true}
          hasError={!!errors.username}
          errorMessage={errors.username ? errors.username.message : ''}
          register={register}
        />
        <div style={{ width: '100%', margin: '1rem 0' }}>
          <Input
            name="email"
            theme={themes.spinTheWheelAIPIA.textInput}
            placeholder="Email"
            required={true}
            hasError={!!errors.email}
            errorMessage={errors.email ? errors.email.message : ''}
            register={register}
          />
        </div>
        <Input
          name="phone"
          theme={themes.spinTheWheelAIPIA.textInput}
          placeholder="Phone"
          hasError={!!errors.phone}
          errorMessage={errors.phone ? errors.phone.message : ''}
          register={register}
        />
        <div style={{ marginTop: '1rem' }}>
          <Button type="submit" theme={themes.spinTheWheelAIPIA.greenButton}>
            Continue
          </Button>
        </div>
      </Form>
    </>
  );
};

export default RegistrationForm;
